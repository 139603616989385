import type { MaybeRefOrGetter } from 'vue'
import { useEventListener } from '@vueuse/core'

export const useHover = (
  el: MaybeRefOrGetter<EventTarget | null | undefined>
) => {
  const isHovered = ref(false)

  // "mouseover", "mouseout", etc. can still be fired on touch devices (tested)
  const handler = (bool: boolean, evt: PointerEvent) => {
    if (evt.pointerType === 'mouse') {
      isHovered.value = bool
    }
  }

  if (typeof window === 'undefined') {
    return isHovered
  }

  useEventListener(
    el,
    'pointerenter',
    (evt: PointerEvent) => handler(true, evt),
    { passive: true }
  )

  useEventListener(
    el,
    'pointerleave',
    (evt: PointerEvent) => handler(false, evt),
    { passive: true }
  )

  return isHovered
}
