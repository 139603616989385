<template>
  <transition :name="$style.fadeIn">
    <div
      ref="root"
      v-show="isShown"
      v-ws-action:pixelbattle="{
        updatePixel: onUpdatePixelAction
      }"
      :class="$style.pixelInfo"
      :style="{
        transform
      }"
    >
      <div v-if="pixel" :class="$style.memberInfo">
        <ui-link :class="$style.link" :to="'/profile/' + pixel.memberId">
          <ui-avatar
            size="custom"
            :class="$style.avatar"
            :src="pixel.member.avatar"
          />

          <div :class="$style.name">
            <ui-username :class="$style.username">
              {{ pixel.member.username }}
            </ui-username>

            <span v-if="0">[{{ pixel.clanId }}]</span>
          </div>
        </ui-link>

        <ui-time :class="$style.timeAgo" format="past" :value="pixel.ts" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import {
  useElementSize,
  useTimestamp,
  useLastChanged,
  useIntervalFn,
  whenever
} from '@vueuse/core'
import UiAvatar from '~/components/ui/avatar/index.vue'
import UiLink from '~/components/ui/link/index.vue'
import UiUsername from '~/components/ui/username/index.vue'
import UiTime from '~/components/ui/time/index.vue'
import { useGameStore } from '../stores/game'
import { usePointerCoords } from '../composables/use-pointer-coords'
import type {
  Pixel,
  GetPixelResponse,
  UpdatePixelActionPayload
} from '../types'

const OFFSET_X = 10
const OFFSET_Y = 20

const props = defineProps<{
  relX: number
  relY: number
  containerWidth: number
  containerHeight: number
}>()

const { $sdk } = useNuxtApp()

const gameStore = useGameStore()
const game = computed(() => gameStore.data!)
const { activeColor } = storeToRefs(gameStore)

const root = ref<HTMLElement | null>(null)
const { width, height } = useElementSize(root)
const timestamp = useTimestamp()

const { x, y, isCanvasHovered } = usePointerCoords(() => game.value.lockZones)

const isHovered = useHover(root)

const {
  holdRemaining,
  internalRelX,
  internalX,
  internalRelY,
  internalY,
  hold
} = useHold()

const { isDelayOver } = useDelay()

const shouldBeShown = computed(
  () =>
    !activeColor.value &&
    (holdRemaining.value > 0 || (isCanvasHovered.value && isDelayOver.value))
)

const { pixel, isPixelPending, onUpdatePixelAction } = usePixel()

const isShown = computed(
  () => shouldBeShown.value && pixel.value && !isPixelPending.value
)

const transform = computed(() => {
  // to avoid blinking
  if (!isShown.value) {
    return 'scale(0)'
  }

  const isRight =
    internalRelX.value > props.containerWidth - width.value - 2 * OFFSET_X
  const isTop =
    internalRelY.value > props.containerHeight - height.value - 2 * OFFSET_Y

  const x =
    internalRelX.value + (isRight ? -(width.value + OFFSET_X) : OFFSET_X)
  const y = internalRelY.value + (isTop ? -(height.value + OFFSET_Y) : OFFSET_Y)

  return `translate3d(${x}px, ${y}px, 0)`
})

function useHold() {
  const HOLD_DELAY = 2000
  const HOLD_TIMER_INTERVAL = 100

  const holdRemaining = ref(0)

  const internalRelX = ref(0)
  const internalX = ref(0)
  const internalRelY = ref(0)
  const internalY = ref(0)

  const { pause, resume, isActive } = useIntervalFn(
    () => {
      holdRemaining.value -= HOLD_TIMER_INTERVAL

      if (holdRemaining.value <= 0) {
        pause()
      }
    },
    HOLD_TIMER_INTERVAL,
    { immediate: false }
  )

  watch(isHovered, bool => {
    if (bool) {
      pause()
    } else {
      resume()
    }
  })

  watchEffect(() => {
    if (holdRemaining.value > 0) {
      return
    }

    internalRelX.value = props.relX
    internalX.value = x.value
    internalRelY.value = props.relY
    internalY.value = y.value
  })

  const hold = () => {
    if (isActive.value || activeColor.value) {
      return
    }

    holdRemaining.value = HOLD_DELAY
    resume()
  }

  return {
    holdRemaining,
    internalRelX,
    internalX,
    internalRelY,
    internalY,
    hold
  }
}

function useDelay() {
  const SHOW_DELAY = 1500

  const xLastChanged = useLastChanged(internalX)
  const yLastChanged = useLastChanged(internalY)

  const coordsLastChanged = computed(() =>
    xLastChanged.value || yLastChanged.value
      ? Math.max(xLastChanged.value ?? 0, yLastChanged.value ?? 0)
      : null
  )

  const isDelayOver = computed(() =>
    coordsLastChanged.value
      ? timestamp.value - coordsLastChanged.value >= SHOW_DELAY
      : false
  )

  return { isDelayOver }
}

function usePixel() {
  const {
    data: pixel,
    pending: isPixelPending,
    execute: fetchPixel
  } = useAsyncData<Pixel>(
    async () => {
      const { pixel: givenPixel } = await $sdk
        .module('pixelbattle')
        .then(({ getWSInstance }) =>
          getWSInstance().emit<GetPixelResponse>('get-pixel', {
            x: internalX.value,
            y: internalY.value
          })
        )

      return givenPixel
    },
    { server: false, immediate: false }
  )

  whenever(shouldBeShown, () => fetchPixel())

  const onUpdatePixelAction = async ({ x, y }: UpdatePixelActionPayload) => {
    if (shouldBeShown.value && internalX.value === x && internalY.value === y) {
      await fetchPixel()
    }
  }

  return { pixel, isPixelPending, fetchPixel, onUpdatePixelAction }
}

defineExpose({ hold })
</script>

<style lang="scss" module>
.pixelInfo {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4em;
}

.memberInfo {
  --avatar-width: 1.6em;
  --link-gap: 0.4em;

  border-radius: 0.6em;
  padding: 0.5em 1.2em 0.5em 0.6em;
  background-color: white;
  box-shadow: 0.2em 0.2em 0.6em rgba(black, 0.25);
}

.avatar {
  --width: var(--avatar-width);
}

.name {
  font-weight: 600;
  color: #0a0a0a;
  display: inline-flex;
  gap: 0.3em;
  transition: color 0.2s;

  span {
    font-size: 1.2em;
    line-height: 1.5;
    letter-spacing: 0.02em;
  }
}

.link {
  display: inline-flex;
  gap: var(--link-gap);
  align-items: flex-start;

  &:hover,
  &:focus-visible {
    .name {
      color: var(--app-active-color);
    }
  }
}

.username {
  max-width: 20em;

  @include down(sm) {
    max-width: 10em;
  }
}

.timeAgo {
  display: block;
  margin-left: calc(var(--avatar-width) + var(--link-gap));
  color: rgba(black, 0.5);
  text-transform: lowercase;

  > span {
    font-size: 1em;
    line-height: 1.1;
  }
}

.fadeIn {
  &:global(-enter-active) {
    transition: opacity 0.3s;
  }

  &:global(-leave-to),
  &:global(-enter-from) {
    opacity: 0;
  }
}
</style>
